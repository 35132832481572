/* Invoice.module.css */

:root {
    --blue-color: #0c2f54;
    --dark-color: #535b61;
    --white-color: #fff;
}

ul {
    list-style-type: none;
}
.invoice-body-info-item {
  display: flex;
  justify-content: space-between; 
  border: 1px solid black;/* This will align items at the ends of the container */
}

.info-item-td {
  flex: 1;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-bold {
  font-weight: bold;
}

ul li {
    margin: 2px 0;
}

/* text colors */
.text-dark {
    color: var(--dark-color);
}

.text-blue {
    color: var(--blue-color);
}

.text-end {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-start {
    text-align: left;
}

.text-bold {
    font-weight: 700;
}

/* hr line */
.hr {
    height: 1px;
    background-color: rgb(0 0 0 / 70%);
}

/* border-bottom */
.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

body {
    font-family: 'Poppins', sans-serif;
    color: var(--dark-color);
    font-size: 14px;
}

.invoice-wrapper {
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
}


.invoice {
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--white-color);
    padding: 7px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    /* min-height: 920px; */
}
.invoice-head-container{
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 0px 20px;
}

.invoice-head-top-left img {
    width: 130px;
}

.invoice-head-top-right h3 {
    font-weight: 500;
    font-size: 27px;
    color: var(--blue-color);
}

.invoice-head-middle,
.invoice-head-bottom {
    padding: 5px 0 0 0;
}

.invoice-body {
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* border-radius: 4px; */
    overflow: hidden;
}

.invoice-body table {
    border-collapse: collapse;
    border-radius: 4px;
    width: 100%;
}

.invoice-body table td,
.invoice-body table th {
    padding: 12px;
    border: 1px solid black;
}

.invoice-body table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.invoice-body table thead {
    background-color: rgba(0, 0, 0, 0.02);
}

.invoice-body-info-item {
    display: grid;
    grid-template-columns: 80% 20%;
}

.invoice-body-info-item .info-item-td {
    padding: 6px;
    background-color: rgba(0, 0, 0, 0.02);
}

.invoice-foot {
    padding: 10px 0;
}

.invoice-foot p {
    font-size: 12px;
}

.invoice-btns {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.invoice-btn {
    padding: 3px 9px;
    /* color: var(--dark-color); */
    color: var(--white-color);
    font-family: inherit;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.invoice-head-top,
.invoice-head-middle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 10px;
    
}


@media screen and (max-width: 992px) {
    .invoice {
        padding: 40px;
    }
}

@media screen and (max-width: 576px) {
    .invoice-head-top,
    .invoice-head-middle{

         grid-template-columns: repeat(1, 1fr);
    
        
    }

    .invoice * {
        text-align: left;
    }

    .invoice {
        padding: 28px;
    }
}

.overflow-view {
    overflow-x: scroll;
}

.invoice-body {
    min-width: 600px;
}

@media print {
    .print-area {
        visibility: visible;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
    }

    .overflow-view {
        overflow-x: hidden;
    }

    .invoice-btns {
        display: none;
    }

    .hr {
        height: 10px;
        background-color: rgb(0 0 0 / 70%);
    }

    @page {
        margin: 0; /* Removes header and footer by setting page margins to 0 */
    }
    body {
        margin: 1cm; /* Adjusts content margin */
    }
}
